import store from "@/state/store";
import Api from "@/helpers/api";

export default [
    {
        path: "/logout",
        name: "logout",
        meta: {
            title: "Logout", authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                localStorage.clear();
                sessionStorage.clear();
                next();
            },
        },
        component: () => import("../views/account/logout")
    },

    {
        path: "/login",
        name: "login",
        component: () => import("../views/account/login.vue"),
        meta: {
            title: "Connexion",
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.state.currentUser) {
                    // Redirect to the home page instead
                    next({name: "dashboard"});
                    next();
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: "/signup",
        name: "signup",
        component: () => import("../views/account/signup.vue"),
        meta: {
            title: "Inscription",
            authRequired: false,

        },
    },
    {
        path: "/confirmation",
        name: "confirmation",
        component: () => import("../views/account/confirmation.vue"),
        meta: {
            title: "Confirmation",
            authRequired: false,

        },
    },
    {
        path: "/activate",
        name: "activateAccount",
        beforeEnter(routeTo, routeFrom, next){
            var api = new Api();
            console.log('test')
            api.get("/users/"+routeTo.query.user)
                .then(response => {
                    //let user = response;
                    if (response.confirmationCode === routeTo.query.code){
                        api.put("/users/"+response.id, {enabled: true})
                            .then(us => {
                                if (us.id){
                                    console.log(us)
                                    localStorage.setItem('currentUser', JSON.stringify(response));
                                    store.dispatch("logIn", response);
                                    next({name: 'dashboard'});
                                }
                            })
                    }else{
                        next({name: 'confirmation'})
                    }
                })
        },

        meta: {
            title: "Confirmation",
            authRequired: false

        },
    },
    {
        path: "/",
        name: "dashboard",
        beforeEnter(routeTo, routeFrom, next) {
            // If the user is already logged in
            if (store.state.currentUser != null) {
               if (store.state.currentUser.entites.length === 0){
                   next({name: 'createEtablissements'})

               }else{
                   next();
               }
            } else {
                // Continue to the login page
                next({name: 'login'});
            }
        },
        meta: {
            title: "Tableau de bord",
            authRequired: true,
        },
        component: () => import("../views/dashboard.vue"),
    },
    {
        path: "/immeubles",
        name: "immeubles",
        meta: {
            title: "Biens immobiliers",
            authRequired: true,
        },
        children: [
            {
                path: "",
                name: "listeBien",
                meta: {
                    title: "Liste des biens immobiliers",
                    authRequired: true,
                },
                component: () => import("@/views/biens/liste"),
            },
            {
                path: ":id/edit",
                name: "editBien",
                meta: {
                    title: "Biens immobiliers",
                    authRequired: true,
                },
                component: () => import("@/views/biens/edit"),
            },
            {
                path: "create",
                name: "createBien",
                meta: {
                    title: "Biens immobiliers",
                    authRequired: true,
                },
                component: () => import("@/views/biens/create"),
            },
            {
                path: ":id/fiche",
                name: "ficheBien",
                meta: {
                    title: "Biens",
                    authRequired: true,
                },
                component: () => import("@/views/biens/fiche"),
            },


        ]
    },
    {
        path: "/lots",
        name: "lots",
        meta: {
            title: "Biens immobiliers",
            authRequired: true,
        },
        children: [
            {
                path: "",
                name: "listLot",
                meta: {
                    title: "Liste des lots",
                    authRequired: true,
                },
                component: () => import("../views/lots/liste"),
            },
            {
                path: ":id/edit",
                name: "editLot",
                meta: {
                    title: "Biens immobiliers",
                    authRequired: true,
                },
                component: () => import("../views/lots/edit"),
            },
            {
                path: ":id/fiche",
                name: "ficheLot",
                meta: {
                    title: "Biens immobiliers",
                    authRequired: true,
                },
                component: () => import("../views/lots/edit"),
            },
            {
                path: "create",
                name: "createLot",
                meta: {
                    title: "Biens immobiliers",
                    authRequired: true,
                },
                component: () => import("../views/lots/create.vue"),
            },
            {
                path: ":id/fiche",
                name: "ficheLot",
                meta: {
                    title: "Lots",
                    authRequired: true,
                },
                component: () => import("../views/lots/fiche"),
            },



        ]
    },

    {
        path: "/finances",
        name: "finances",
        meta: {
            title: "Finances",
            authRequired: true,
        },
        children: [
            {
                path: "/paiement-loyer",
                name: "paiementLoyer",
                meta: {
                    title: "Paiement de loyer",
                    authRequired: true,
                },
                component: () => import("../views/finances/loyer"),
            },
        ]
    },
    {
        path: "/locataires",
        name: "locataires",
        meta: {
            title: "Locataires",
            authRequired: true,
        },
        children: [
            {
                path: "",
                name: "listeLocataire",
                meta: {
                    title: "Liste des locataires",
                    authRequired: true,
                },
                component: () => import("../views/locataires/liste"),
            },
            {
                path: ":id/edit",
                name: "editLocataire",
                meta: {
                    title: "Locataires",
                    authRequired: true,
                },
                component: () => import("../views/locataires/edit.vue"),
            },

            {
                path: "create",
                name: "createLocataire",
                meta: {
                    title: "Locataires",
                    authRequired: true,
                },
                component: () => import("../views/locataires/create"),
            },
            {
                path: ":id/fiche",
                name: "ficheLocataire",
                meta: {
                    title: "Locataires",
                    authRequired: true,
                },
                component: () => import("../views/locataires/fiche"),
            },
            {
                path: ":id/solde",
                name: "soldeLocataire",
                meta: {
                    title: "Locataires",
                    authRequired: true,
                },
                component: () => import("../views/locataires/solde"),
            },


        ]
    },
    {
        path: "/locations",
        name: "locations",
        meta: {
            title: "Locations",
            authRequired: true,
        },
        children: [
            {
                path: "",
                name: "listeLocations",
                meta: {
                    title: "Liste des locations",
                    authRequired: true,
                },
                component: () => import("../views/locations/liste"),
            },
            {
                path: ":id/edit",
                name: "editLocations",
                meta: {
                    title: "Locataires",
                    authRequired: true,
                },
                component: () => import("../views/locations/edit.vue"),
            },
            {
                path: ":id/terminer-location",
                name: "resilierLocations",
                meta: {
                    title: "Locations",
                    authRequired: true,
                },
                component: () => import("../views/locations/resilier.vue"),
            },
            {
                path: ":id/contrat",
                name: "contratLocation",
                meta: {
                    title: "Locataires",
                    authRequired: true,
                },
                component: () => import("../views/locations/contrat.vue"),
            },
            {
                path: "create",
                name: "createLocations",
                meta: {
                    title: "Locations",
                    authRequired: true,
                },
                component: () => import("../views/locations/create"),
            },


        ]
    },
    {
        path: "/type-emplacements",
        name: "typeEmplacements",
        meta: {
            title: "Type Emplacements",
            authRequired: true,
        },
        children: [
            {
                path: "",
                name: "listeTypeEmplacement",
                meta: {
                    title: "Liste des Types emplacements",
                    authRequired: true,
                },
                component: () => import("../views/configurations/type_emplacement/liste"),
            },
            {
                path: ":id/edit",
                name: "editTypeEmplacement",
                meta: {
                    title: "Type Emplacements",
                    authRequired: true,
                },
                component: () => import("../views/configurations/type_emplacement/edit"),
            },
            {
                path: "create",
                name: "createTypeEmplacement",
                meta: {
                    title: "Locations",
                    authRequired: true,
                },
                component: () => import("../views/configurations/type_emplacement/create.vue"),
            },


        ]
    },
    {
        path: "/emplacements",
        name: "emplacements",
        meta: {
            title: "Emplacements",
            authRequired: true,
        },
        children: [
            {
                path: "",
                name: "listeEmplacement",
                meta: {
                    title: "Liste des emplacements",
                    authRequired: true,
                },
                component: () => import("../views/configurations/emplacement/liste"),
            },
            {
                path: ":id/edit",
                name: "editEmplacement",
                meta: {
                    title: "Emplacements",
                    authRequired: true,
                },
                component: () => import("../views/configurations/emplacement/edit"),
            },
            {
                path: "create",
                name: "createEmplacement",
                meta: {
                    title: "Emplacements",
                    authRequired: true,
                },
                component: () => import("../views/configurations/emplacement/create.vue"),
            },


        ]
    },
    {
        path: "/utilisateurs",
        name: "utilisateurs",
        meta: {
            title: "Utilisateurs",
            authRequired: true,
        },
        children: [
            {
                path: "",
                name: "listUtilisateurs",
                meta: {
                    title: "Liste des utilisateurs",
                    authRequired: true,
                },
                component: () => import("../views/configurations/utilisateurs/liste"),
            },
            {
                path: ":id/edit",
                name: "editUtilisateurs",
                meta: {
                    title: "Utilisateurs",
                    authRequired: true,
                },
                component: () => import("../views/configurations/utilisateurs/edit"),
            },
            {
                path: "create",
                name: "createUtilisateurs",
                meta: {
                    title: "Utilisateurs",
                    authRequired: true,
                },
                component: () => import("../views/configurations/utilisateurs/create.vue"),
            },


        ]
    },
    {
        path: "/agents",
        name: "agents",
        meta: {
            title: "Agents",
            authRequired: true,
        },
        children: [
            {
                path: "",
                name: "listAgents",
                meta: {
                    title: "Liste des agents",
                    authRequired: true,
                },
                component: () => import("../views/configurations/agent/liste"),
            },
            {
                path: ":id/edit",
                name: "editAgents",
                meta: {
                    title: "Agents",
                    authRequired: true,
                },
                component: () => import("../views/configurations/agent/edit"),
            },
            {
                path: "create",
                name: "createAgents",
                meta: {
                    title: "Agents",
                    authRequired: true,
                },
                component: () => import("../views/configurations/agent/create.vue"),
            },


        ]
    },
    {
        path: "/pays",
        name: "pays",
        meta: {
            title: "Pays",
            authRequired: true,
        },
        children: [
            {
                path: "",
                name: "listPays",
                meta: {
                    title: "Liste des pays",
                    authRequired: true,
                },
                component: () => import("../views/configurations/pays/liste"),
            },
            {
                path: ":id/edit",
                name: "editPays",
                meta: {
                    title: "Pays",
                    authRequired: true,
                },
                component: () => import("../views/configurations/pays/edit"),
            },
            {
                path: "create",
                name: "createPays",
                meta: {
                    title: "Pays",
                    authRequired: true,
                },
                component: () => import("../views/configurations/pays/create.vue"),
            },


        ]
    },
    {
        path: "/types-biens",
        name: "types-biens",
        meta: {
            title: "Types de biens",
            authRequired: true,
        },
        children: [
            {
                path: "",
                name: "listTypeBiens",
                meta: {
                    title: "Liste des types de biens",
                    authRequired: true,
                },
                component: () => import("../views/configurations/types-biens/liste"),
            },
            {
                path: ":id/edit",
                name: "editTypeBien",
                meta: {
                    title: "Types de biens",
                    authRequired: true,
                },
                component: () => import("../views/configurations/types-biens/edit"),
            },
            {
                path: "create",
                name: "createTypeBien",
                meta: {
                    title: "Types de biens",
                    authRequired: true,
                },
                component: () => import("../views/configurations/types-biens/create.vue"),
            },


        ]
    },
    {
        path: "/villes",
        name: "villes",
        meta: {
            title: "Villes",
            authRequired: true,
        },
        children: [
            {
                path: "",
                name: "listVilles",
                meta: {
                    title: "Liste des villes",
                    authRequired: true,
                },
                component: () => import("../views/configurations/villes/liste"),
            },
            {
                path: ":id/edit",
                name: "editVilles",
                meta: {
                    title: "Villes",
                    authRequired: true,
                },
                component: () => import("../views/configurations/villes/edit"),
            },
            {
                path: "create",
                name: "createVilles",
                meta: {
                    title: "Villes",
                    authRequired: true,
                },
                component: () => import("../views/configurations/villes/create.vue"),
            },


        ]
    },
    {
        path: "/etablissements",
        name: "etablissements",
        meta: {
            title: "Etablissements",
            authRequired: true,
        },
        children: [
            {
                path: "",
                name: "listEtablissements",
                meta: {
                    title: "Liste des établissements",
                    authRequired: true,
                },
                component: () => import("../views/configurations/etablissement/liste"),
            },
            {
                path: ":id/edit",
                name: "editEtablissements",
                meta: {
                    title: "Etablissements",
                    authRequired: true,
                },
                component: () => import("../views/configurations/etablissement/edit"),
            },
            {
                path: "create",
                name: "createEtablissements",
                meta: {
                    title: "Etablissements",
                    authRequired: true,
                },
                component: () => import("../views/configurations/etablissement/create.vue"),
            },


        ]
    },
    {
        path: "/roles",
        name: "roles",
        meta: {
            title: "Rôles",
            authRequired: true,
        },
        children: [
            {
                path: "",
                name: "listRoles",
                meta: {
                    title: "Liste des rôles",
                    authRequired: true,
                },
                component: () => import("../views/configurations/role/liste"),
            },
            {
                path: ":id/edit",
                name: "editRoles",
                meta: {
                    title: "Roles",
                    authRequired: true,
                },
                component: () => import("../views/configurations/role/edit"),
            },
            {
                path: "create",
                name: "createRoles",
                meta: {
                    title: "Roles",
                    authRequired: true,
                },
                component: () => import("../views/configurations/role/create.vue"),
            },


        ]
    },
    {
        path: "/groupes",
        name: "groupes",
        meta: {
            title: "Groupes",
            authRequired: true,
        },
        children: [
            {
                path: "",
                name: "listGroupes",
                meta: {
                    title: "Liste des groupes",
                    authRequired: true,
                },
                component: () => import("../views/configurations/groupe/liste"),
            },
            {
                path: ":id/edit",
                name: "editGroupes",
                meta: {
                    title: "Groupes",
                    authRequired: true,
                },
                component: () => import("../views/configurations/groupe/edit"),
            },
            {
                path: "create",
                name: "createGroupes",
                meta: {
                    title: "Groupes",
                    authRequired: true,
                },
                component: () => import("../views/configurations/groupe/create.vue"),
            },


        ]
    },
    {
        path: "/depenses",
        name: "depenses",
        meta: {
            title: "Dépenses",
            authRequired: true,
        },
        children: [
            {
                path: "",
                name: "listeDepenses",
                meta: {
                    title: "Liste des dépenses",
                    authRequired: true,
                },
                component: () => import("../views/finances/depenses/liste"),
            },
            {
                path: "create",
                name: "createDepenses",
                meta: {
                    title: "Créer une dépense",
                    authRequired: true,
                },
                component: () => import("../views/finances/depenses/create.vue"),
            },
            {
                path: ":id/edit",
                name: "editDepenses",
                meta: {
                    title: "Modifier une dépense",
                    authRequired: true,
                },
                component: () => import("../views/finances/depenses/edit.vue"),
            },

        ]
    },
    {
        path: "/frequences",
        name: "frequences",
        meta: {
            title: "Frequences",
            authRequired: true,
        },
        children: [
            {
                path: "",
                name: "listFrequences",
                meta: {
                    title: "Liste des fréquences",
                    authRequired: true,
                },
                component: () => import("../views/configurations/frequence/liste"),
            },
            {
                path: ":id/edit",
                name: "editFrequences",
                meta: {
                    title: "Frequences",
                    authRequired: true,
                },
                component: () => import("../views/configurations/frequence/edit"),
            },
            {
                path: "create",
                name: "createFrequences",
                meta: {
                    title: "Frequences",
                    authRequired: true,
                },
                component: () => import("../views/configurations/frequence/create.vue"),
            },


        ]
    },
    {
        path: "/moyen_paiement",
        name: "moyen_paiement",
        meta: {
            title: "Moyens de paiements",
            authRequired: true,
        },
        children: [
            {
                path: "",
                name: "listMoyenPaiement",
                meta: {
                    title: "Liste des moyens de paiement",
                    authRequired: true,
                },
                component: () => import("../views/configurations/moyen-paiement/liste"),
            },
            {
                path: ":id/edit",
                name: "editMoyenPaiement",
                meta: {
                    title: "Moyens de paiements",
                    authRequired: true,
                },
                component: () => import("../views/configurations/moyen-paiement/edit"),
            },
            {
                path: "create",
                name: "createMoyenPaiement",
                meta: {
                    title: "Moyens de paiements",
                    authRequired: true,
                },
                component: () => import("../views/configurations/moyen-paiement/create.vue"),
            },


        ]
    },
    {
        path: "/type-utilisateur",
        name: "type_user",
        meta: {
            title: "Types d'utilisateurs",
            authRequired: true,
        },
        children: [
            {
                path: "",
                name: "listTypeUser",
                meta: {
                    title: "Liste des types d'utilisateurs",
                    authRequired: true,
                },
                component: () => import("../views/configurations/types-users/liste"),
            },
            {
                path: ":id/edit",
                name: "editTypeUser",
                meta: {
                    title: "Types d'utilisateurs",
                    authRequired: true,
                },
                component: () => import("../views/configurations/types-users/edit"),
            },
            {
                path: "create",
                name: "createTypeUser",
                meta: {
                    title: "Types d'utilisateurs",
                    authRequired: true,
                },
                component: () => import("../views/configurations/types-users/create.vue"),
            },


        ]
    },
    {
        path: "/quittances",
        name: "quittances",
        meta: {
            title: "Mes Quittances",
            authRequired: true,
        },
        children: [
            {
                path: "",
                name: "listQuittances",
                meta: {
                    title: "Mes Quittances",
                    authRequired: true,
                },
                component: () => import("../views/finances/quittances/liste.vue"),
            },
            {
                path: ":id/paiement",
                name: "paiementQuittances",
                meta: {
                    title: "Mes Quittances",
                    authRequired: true,
                },
                component: () => import("../views/finances/quittances/paiement.vue"),
            },
            {
                path: ":id/recu-de-paiement",
                name: "recuQuittances",
                meta: {
                    title: "Mes Quittances",
                    authRequired: true,
                },
                component: () => import("../views/finances/quittances/recu.vue"),
            },


        ]
    },
    {
        path: "/rapports",
        name: "rapports",
        meta: {
            title: "Rapports",
            authRequired: true,
        },
        children: [
            {
                path: "/rapports-impayer",
                name: "rapportImpayer",
                meta: {
                    title: "Rapport Impayer",
                    authRequired: true,
                },
                component: () => import("../views/rapports/impayer.vue"),
            },
            {
                path: "/loyer-encaisser",
                name: "rapportLoyerEncaisser",
                meta: {
                    title: "Rapport loyer encaissé",
                    authRequired: true,
                },
                component: () => import("../views/rapports/loyerEncaisser.vue"),
            },
            {
                path: "/loyer-non-encaisser",
                name: "rapportLoyerNonEncaisser",
                meta: {
                    title: "Rapport loyer non encaissé",
                    authRequired: true,
                },
                component: () => import("../views/rapports/loyerNonEncaisser.vue"),
            },
            {
                path: "/loyer-depot-garanti",
                name: "rapportDepotGaranti",
                meta: {
                    title: "Rapport des dépôts de garanti",
                    authRequired: true,
                },
                component: () => import("../views/rapports/depotGaranti.vue"),
            },
            {
                path: "/tva-collecte",
                name: "rapportTva",
                meta: {
                    title: "Rapport des TVA collectés",
                    authRequired: true,
                },
                component: () => import("../views/rapports/tvaCollecte.vue"),
            },


        ]
    },
    {
        path: "/paiements",
        name: "invoices",
        meta: {
            title: "Quittances",
            authRequired: true,
        },
        children: [
            {
                path: "",
                name: "invoiceCreate",
                meta: {
                    title: "Paiement quittances",
                    authRequired: true,
                },
                component: () => import("../views/finances/paiement.vue"),
            },
            {
                path: ":id/print-invoice",
                name: "printInvoice",
                meta: {
                    title: "Paiement quittances",
                    authRequired: true,
                },
                component: () => import("../views/finances/facture.vue"),
            },


        ]
    },
    {
        path: "/accounts",
        name: "accounts",
        meta: {
            title: "Comptes",
            authRequired: true,
        },
        children: [
            {
                path: "settings",
                name: "accountSettings",
                meta: {
                    title: "Reglages",
                    authRequired: true,
                },
                component: () => import("../views/account/settings.vue"),
            },
            {
                path: "profile",
                name: "editProfile",
                meta: {
                    title: "Profile",
                    authRequired: true,
                },
                component: () => import("../views/account/profile.vue"),
            },
            {
                path: "change-password",
                name: "changePassword",
                meta: {
                    title: "Profile",
                    authRequired: true,
                },
                component: () => import("../views/account/motdepasse.vue"),
            },


        ]
    },
    {
        path: "/landlords",
        name: "landlords",
        meta: {
            title: "Multi-propriétaire",
            authRequired: true,
        },
        children: [
            {
                path: "",
                name: "listLandlords",
                meta: {
                    title: "Multi-propriétaire",
                    authRequired: true,
                },
                component: () => import("../views/landlords/liste.vue"),
            },
            {
                path: "create",
                name: "createLandlords",
                meta: {
                    title: "Multi-propriétaire",
                    authRequired: true,
                },
                component: () => import("../views/landlords/create.vue"),
            },
            {
                path: ":id/edit",
                name: "editLandlords",
                meta: {
                    title: "Multi-propriétaire",
                    authRequired: true,
                },
                component: () => import("../views/landlords/edit.vue"),
            },
            {
                path: ":id/mandat",
                name: "mandatGestion",
                meta: {
                    title: "Multi-propriétaire",
                    authRequired: true,
                },
                component: () => import("../views/landlords/mandat.vue"),
            },


        ]
    },


]