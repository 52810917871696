import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";
import Select2 from 'vue3-select2-component'
import VueQRCodeComponent from 'vue-qrcode-component'
import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';


import VueFeather from 'vue-feather';
import print from 'vue3-print-nb'

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Maska from 'maska';

import '@/assets/scss/config/default/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

createApp(App)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(print)
    .component('select2', Select2)
    .component('simplebar', simplebar)
    .component('qr-code', VueQRCodeComponent)
    .component('Datepicker', Datepicker)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(i18n)
    .use(vClickOutside).mount('#app');